<template>
  <div class="list">
    <search-box @change="research" @export="_exportForm"></search-box>
    <div class="contentBox">
      <div class="contentTool">
        <!-- <el-button
          :disabled="ids.length === 0"
          @click="submit"
          type="primary"
          size="medium"
        >
          申请
        </el-button> -->
        <el-button @click="add" type="primary" size="medium"> 新增 </el-button>
      </div>
      <el-table
        class="contentTable"
        @selection-change="handleSelectionChange"
        :data="tableData"
      >
        <!-- <el-table-column
          type="selection"
          :selectable="(row) => row.status === 0 && row.activityKeyValue"
          width="55"
        >
        </el-table-column> -->
        <el-table-column prop="jobNumber" label="工号">
          <template slot-scope="scope">
            {{ scope.row.jobNumber || "无" }}
          </template>
        </el-table-column>
        <el-table-column prop="staffName" label="姓名">
          <template slot-scope="scope">
            {{ scope.row.staffName || "无" }}
          </template>
        </el-table-column>
        <el-table-column prop="sysDeptName" label="部门">
          <template slot-scope="scope">
            {{ scope.row.sysDeptName ? scope.row.sysDeptName.deptName : "无" }}
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="手机号">
          <template slot-scope="scope">
            {{ scope.row.mobile || "无" }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="creatorName" label="岗位">
          <template slot-scope="scope">
            {{
              scope.row.sysPositionNameList.map((e) => e.positionName).join(",")
            }}
          </template>
        </el-table-column> -->
        <el-table-column prop="createTime" label="职级">
          <template slot-scope="scope">
            {{ scope.row.sysRankLevelNameList && scope.row.sysRankLevelNameList.map((e) => e.content).join(",") }}
          </template>
        </el-table-column>
        <el-table-column prop="preEntryTime" label="入职时间">
          <template slot-scope="scope">
            {{ scope.row.preEntryTime || "无" }}
          </template>
        </el-table-column>
        <el-table-column prop="workName" label="工作地点"></el-table-column>
        <el-table-column prop="status" label="审核状态">
          <template slot-scope="scope">
            <span>{{ $reconava.getStatusName(scope.row.status) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createDate"
          label="操作"
          class-name="operation"
          width="150"
        >
          <template slot-scope="scope">
            <span @click="see(scope.row)" class="blue">查看</span>
            <!--     审批通过后，可以打印详情           -->
            <span class="mgr10 blue" v-if="scope.row.status===4" @click="_print(scope.row.id)">打印</span>
            <span
              v-if="
                [0, 3, 5].includes(scope.row.status) &&
                scope.row.createBySysUserName.id == userInfo.id
              "
              @click="edit(scope.row)"
              class="blue"
              >编辑</span
            >
            <!-- <span
              v-if="
                scope.row.status == 4 &&
                scope.row.canApprovalPersonOperation &&
                scope.row.createBySysUserName.id == userInfo.id
              "
              @click="flow(scope.row)"
              class="blue"
              >流转</span
            > -->
            <span
              v-if="
                [0, 101, 102].includes(scope.row.status) &&
                scope.row.createBySysUserName.id == userInfo.id
              "
              @click="revoke(scope.row)"
              class="blue"
              >撤销</span
            >
            <span
              v-if="
                [3].includes(scope.row.status) &&
                scope.row.createBySysUserName.id == userInfo.id
              "
              @click="stop(scope.row)"
              class="blue"
              >终止</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="qrCodeUrl"
          label="二维码"
          class-name="operation"
          width="150"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.status == 102 && !scope.row.qrCodeUrl"
              >二维码生成中……</span
            >
            <template v-if="scope.row.qrCodeUrl">
              <img
                @click="preview(scope.row)"
                :src="scope.row.qrCodeUrl"
                alt=""
                style="width: 50px; height: 50px"
                :class="scope.row.qrCodeValid ? '' : 'blur'"
              />
            </template>
          </template>
        </el-table-column>
      </el-table>
      <ReconovaPagination
        @paginationChange="paginationChange"
        :page-no="pageNum"
        :page-size="pageSize"
        :total="total"
      >
      </ReconovaPagination>
    </div>
  </div>
</template>

<script>
import SearchBox from "./components/searchBox";
import ReconovaPagination from "@/components/reconova/reconovaPagination";
import { operatorDialog } from "@/utils/utils.js";
import {onDownload} from "@/utils/utils";
import {aDownload} from "@/utils/utils";
export default {
  name: "list",
  components: {
    ReconovaPagination,
    SearchBox,
  },
  data() {
    return {
      currentId: "",
      pageNum: 1,
      pageSize: 10,
      search: { inducApprStatus: 0 },
      tableData: [],
      total: 1,
      ids: [],
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
    };
  },
  methods: {
    stop(item) {
      operatorDialog(this, "理由", "终止", (value) => {
        let param = {
          id: item.id,
          typeId: 5,
          reason: value || "",
        };
        this.$api.checkapi.terminateMyApply(param).then((response) => {
          this.$message.success("已终止");
          this.getData();
        });
      });
    },
    preview(item) {
      if (item.qrCodeValid) {
        this.$msgbox({
          confirmButtonText: "下载",
          showCancelButton: true,
          message: (
            <div style="width: 400px; height: 400px; display: flex; justify-content: center; align-items: center;">
              <img src={item.qrCodeUrl} style="width: 350px; height: 350px;" />
            </div>
          ),
          beforeClose(action, instance, done) {
            if (action == "confirm") {
              this.$axios
                .get(item.qrCodeUrl, {
                  responseType: "blob",
                  headers: {
                    "cache-control": "no-cache",
                  },
                })
                .then((res) => {
                  aDownload(res.data,`${item.staffName}+${item.mobile}`)
                  done();
                });
            } else {
              done();
            }
          },
        }).catch((e) => {});
      }
    },
    flow(item) {
      this.$router.push({
        path: `/businessModule/personnel/entryFlow`,
        query: {
          type: "add",
          jobNumber: item.jobNumber,
        },
      });
    },
    revoke(item) {
      operatorDialog(this, "理由", "撤销", (value) => {
        let param = {
          id: item.id,
          typeId: 5,
          reason: value || "",
        };
        this.$api.checkapi.repealMyApply(param).then((response) => {
          this.$message.success("已撤销");
          this.getData();
        });
      });
      // this.$api.personnel
      //   .inductionApprovalRevoke({
      //     id: item.id,
      //     reason: "",
      //   })
      //   .then((res) => {
      //     if (res.code == 200) {
      //       this.$message.success("撤销成功");
      //       this.getData();
      //     }
      //   });
    },
    submit() {
      this.$api.personnel
        .inductionApprovalSubmit({
          ids: this.ids,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("申请已提交");
            this.getData();
            this.ids = [];
          }
        });
    },
    add() {
      this.$emit("changeType", {
        type: "add",
      });
    },
    handleSelectionChange(data) {
      this.ids = data.map((e) => e.id);
    },
    see(row) {
      this.$emit("changeType", {
        type: "detail",
        id: row.id + "",
        viewStatus: "saved",
      });
    },
    edit(item) {
      if (item.status == 101) {
        this.$emit("changeType", {
          type: "add",
          id: item.id + "",
        });
      } else {
        this.$emit("changeType", {
          type: "detail",
          id: item.id + "",
          viewStatus: "edit",
        });
      }
    },
    paginationChange(type, val) {
      //翻页
      if (type === "pageNo") {
        this.pageNum = val;
      } else {
        this.pageNum = 1;
        this.pageSize = val;
      }
      this.getData();
    },
    research(data, isreset) {
      this.pageNum = 1;
      this.pageSize = isreset ? 10 : this.pageSize;
      this.search = Object.assign(this.search, data);
      this.getData();
    },
    getData() {
      this.$api.personnel
        .inductionApprovalList({
          ...this.search,
          pageSize: this.pageSize,
          pageNum: this.pageNum,
        })
        .then((res) => {
          if (res.data) {
            this.tableData = res.data.list;
            this.total = res.data.total;
          }
        });
    },

    _exportForm(data){
      this.$api.personnel.inductionApprovalExport(Object.assign({pageSize:10000,pageNum:1},data)).then(({headers,data})=>{
        onDownload(headers,data)
      })
    },
    _print(id){
      this.$router.push({path:'/businessModule/personnel/print',query:{id}})
    }
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss">
.list {
  display: flex;
  flex-direction: column;
  height: 100%;
  .contentBox {
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    .el-table {
      overflow-y: auto;
      flex: 1;
    }
  }
  .operation {
    span {
      margin-right: 8px;
    }
  }
  .blur {
    filter: blur(5px);
  }
}
</style>