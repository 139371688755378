<template>
  <div class="add">
    <h2 class="title">入职申请</h2>
    <el-form
      ref="form"
      :model="form"
      label-width="100px"
      class="entry-form"
      :rules="formRules"
    >
          <el-form-item label="姓名" prop="staffName">
            <el-input size="medium" v-model="form.staffName" placeholder="请填写姓名"></el-input>
          </el-form-item>

          <el-form-item label="手机号" prop="mobile">
            <el-input maxlength="11"  size="medium" v-model="form.mobile" placeholder="请填写手机号"></el-input>
          </el-form-item>
          <el-form-item label="聘用部门" prop="deptId">
            <department v-model="form.deptId" :checkStrictly="true"></department>
          </el-form-item>
          <el-form-item label="聘用岗位" prop="positionIds">
            <position v-model="form.positionIds" :checkStrictly="true"></position>
          </el-form-item>
          <el-form-item label="兼职岗位" prop="ptPosition">
            <el-input size="medium"
              v-model="form.ptPosition"
              placeholder="请填写兼职岗位"
            ></el-input>
          </el-form-item>
          <el-form-item label="职级" prop="rankLevelIds">
            <position type="grade" :checkStrictly="true" v-model="form.rankLevelIds"></position>
          </el-form-item>
          <el-form-item label="拟入职时间" prop="preEntryTime">
            <date v-model="form.preEntryTime"></date>
          </el-form-item>
          <el-form-item label="工作地点" prop="workId">
            <department v-model="form.workId" :checkStrictly="true"></department>
            <!-- <place v-model="form.workPlace" type="region"></place> -->
          </el-form-item>
    </el-form>
    <div class="operation">
      <el-button @click="back" size="large" class="mgr10">返回</el-button>
      <el-button @click="reset" size="large">重置</el-button>
      <el-button type="primary" @click="onSubmit" size="large" v-throttle
        >确认</el-button
      >
    </div>
  </div>
</template>

<script>
import position from "@/components/position.vue";
import Place from "@/components/place.vue";
import Department from "@/components/department.vue";
import Date from "../../../../components/date.vue";

export default {
  components: { position, Place, Department, Date },
  name: "add",
  data() {
    return {
      form: {
        deptId: "",
        mobile: "",
        positionIds: [],
        preEntryTime: "",
        ptPosition: "",
        rankLevelIds: [],
        staffName: "",
        workId: "",
      },
      formRules: {
        staffName: this.$formValidator.empty("姓名"),
        mobile: this.$formValidator.empty("手机号"),
        deptId: this.$formValidator.empty("聘用部门", "change", "number"),
        positionIds: this.$formValidator.empty("聘用岗位", "change", "array"),
        // ptPosition: this.$formValidator.empty("兼职岗位"),
        rankLevelIds: this.$formValidator.empty("职级", "change", "array"),
        preEntryTime: this.$formValidator.empty("拟入职时间"),
        workId: this.$formValidator.empty("工作地点", "change", "number"),
      },
      draftId: "",
    };
  },
  methods: {
    reset() {
      this.$confirm("是否确认重置？").then(() => {
        this.$api.personnel.reset({ typeId: 5 }).then((res) => {
          let id = this.form.id;
          this.form = {
            deptId: "",
            mobile: "",
            positionIds: [],
            preEntryTime: "",
            ptPosition: "",
            rankLevelIds: [],
            staffName: "",
            workId: "",
            id
          };
        });
      });
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$api.personnel.inductionApprovalInsert(this.form).then((res) => {
            if (res.code == 200) {
              this.$message.success("提交成功");
              this.back();
            }
          });
        }
      });
    },
    back() {
      this.$emit("changeType", {
        type: "list",
      });
    },
    setDraft() {
      let func,
        params = Object.assign({}, this.form);
      if (this.draftId) {
        func = this.$api.personnel.inductionApprovalDraftUpdate;
        params.id = this.draftId;
      } else {
        func = this.$api.personnel.inductionApprovalDraftInsert;
      }
      func(params).then(({ data }) => {
        if (data && data.id) {
          this.draftId = data.id;
          this.form.id = data.id;
        }
      });
    },
    getDraft() {
      this.$api.personnel.inductionApprovalGetUserDraft().then(({ data }) => {
        if (data.id) {
          this.draftId = data.id;
          Object.assign(this.form, data);
        }
      });
    },
  },
  created() {
    this.getDraft();
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.setDraft();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.add {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-bottom: 20px;
  .title {
    text-align: center;
    margin: 32px;
    font-size: 24px;
  }
  .entry-form {
    margin:0 auto;
    .el-form-item {
      margin-bottom: 32px;
      width: 400px;
    }
  }
  .operation {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    text-align: center;
  }
}
::v-deep .entry-form .el-form-item__content .el-input{
  width: 260px;
}
</style>