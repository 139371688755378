<template>
  <block
    title="基本入职信息"
    class="basic-info"
    v-slot="scope"
    :status="$attrs['status']"
    :editable="false"
  >
    <template v-if="scope.status == 'saved'">
      <el-form :model="_value">
        <el-form-item label="姓名">
          {{ _value.staffName }}
        </el-form-item>
        <el-form-item label="手机号">
          {{ _value.mobile }}
        </el-form-item>
        <el-form-item label="身份证号">
          {{ _value.idCard }}
        </el-form-item>
        <el-form-item label="出生日期">
          {{ _value.birthday }}
        </el-form-item>
        <el-form-item label="年龄">
          {{ _value.age }}
        </el-form-item>
        <el-form-item label="民族">
          {{ _value.nation }}
        </el-form-item>
        <el-form-item label="籍贯">
          {{ _value.nativePlace | address }}
        </el-form-item>
        <el-form-item label="性别">
          {{ _value.sex | map("sex") }}
        </el-form-item>
        <el-form-item label="婚姻状况">
          {{ _value.maritalStatus | map('maritalStatus')}}
        </el-form-item>
        <el-form-item label="出生地">
          {{ _value.birthAddress | address }}
        </el-form-item>
        <el-form-item label="政治面貌">
          {{ _value.politicalStatus | map('politicalStatus') }}
        </el-form-item>
        <el-form-item label="入党时间" v-if="_value.politicalStatus == 1">
          {{ _value.joinPartyTime }}
        </el-form-item>
        <div
          style="
            border-top: 1px solid lightgray;
            width: 100%;
            margin-bottom: 10px;
          "
        ></div>
        <el-form-item label="学历">
          {{ _value.education | map('education')  }}
        </el-form-item>
        <el-form-item label="现有职称">
          {{ _value.technicalTitle }}
        </el-form-item>
        <el-form-item label="户口地址">
          {{ _value.residenceAddress }}
        </el-form-item>
        <el-form-item label="家庭住址">
          {{ _value.homeAddress }}
        </el-form-item>
        <el-form-item label="人事档案存放单位">
          {{ _value.personnelFilePlace }}
        </el-form-item>
        <el-form-item label="参加工作时间">
          {{ _value.joinWorkTime }}
        </el-form-item>
        <el-form-item label="工龄">
          {{ _value.workAge }}
        </el-form-item>
        <el-form-item label="开户行">
          {{ _value.bankName }}
        </el-form-item>
        <el-form-item label="银行账号">
          {{ _value.bankAccount }}
        </el-form-item>
        <el-form-item label="邮箱">
          {{ _value.emailAddress }}
        </el-form-item>
        <el-form-item label="担保人姓名">
          {{ _value.guarantorName }}
        </el-form-item>
        <el-form-item label="与担保人关系">
          {{ _value.guarantorRelation }}
        </el-form-item>
        <el-form-item label="担保人联系电话">
          {{ _value.guarantorPhone }}
        </el-form-item>
        <el-form-item label="担保人工作单位或居住住址">
          {{ _value.guarantorAddress }}
        </el-form-item>
        <el-form-item label="担保人身份证号码">
          {{ _value.guarantorIdCard }}
        </el-form-item>
        <el-form-item label="聘用部门">
          {{ _value.sysDeptName ? _value.sysDeptName.deptName : '无'}}
        </el-form-item>
        <el-form-item label="聘用岗位">
          {{ _value.sysPositionNameList && _value.sysPositionNameList.map(e => e.positionName).join(',') }}
        </el-form-item>
        <el-form-item label="职级">
          {{ _value.sysRankLevelNameList && _value.sysRankLevelNameList.map(e => e.content).join(',') }}
        </el-form-item>
        <el-form-item label="兼职岗位">
          {{ _value.ptPosition }}
        </el-form-item>
        <el-form-item label="拟入职时间">
          {{ _value.preEntryTime }}
        </el-form-item>
        <el-form-item label="工作地点">
          {{ _value.workName }}
        </el-form-item>
      </el-form>
    </template>
    <template v-if="scope.status == 'edit'">
      <el-form :model="_value">
        <el-form-item label="姓名">
          <el-input v-model="_value.staffName" :disabled="_value.status == 3"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="_value.mobile" :disabled="_value.status == 3"></el-input>
        </el-form-item>
        <el-form-item label="身份证号">
          <el-input v-model="_value.idCard"></el-input>
        </el-form-item>
        <el-form-item label="出生日期">
          <date v-model="_value.birthday"></date>
        </el-form-item>
        <el-form-item label="年龄">
          <el-input v-model="_value.age"></el-input>
        </el-form-item>
        <el-form-item label="民族">
          <el-input v-model="_value.nation"></el-input>
        </el-form-item>
        <el-form-item label="籍贯">
          <place v-model="_value.nativePlace" type="region"></place>
        </el-form-item>
        <el-form-item label="性别">
          <r-sex v-model="_value.sex"></r-sex>
        </el-form-item>
        <el-form-item label="婚姻状况">
          <el-select
            v-model="_value.maritalStatus"
            placeholder="请选择婚姻状况"
          >
            <el-option
              :label="e.label"
              :value="e.value"
              v-for="(e, i) in $map.getter('maritalStatus')"
              :key="i"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出生地">
          <place v-model="_value.birthAddress" type="region"></place>
        </el-form-item>
        <el-form-item label="政治面貌">
          <el-select
            v-model="_value.politicalStatus"
            placeholder="请选择政治面貌"
          >
            <el-option
              :label="e.label"
              :value="e.value"
              v-for="(e, i) in $map.getter('politicalStatus')"
              :key="i"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入党时间" v-if="_value.politicalStatus == 1">
          <date v-model="_value.joinPartyTime"></date>
        </el-form-item>
        <div
          style="
            border-top: 1px solid lightgray;
            width: 100%;
            margin-bottom: 10px;
          "
        ></div>
        <el-form-item label="学历">
          <el-select v-model="_value.education" placeholder="请选择学历">
            <el-option
              :label="e.label"
              :value="e.value"
              v-for="(e, i) in $map.getter('education')"
              :key="i"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="现有职称">
          <el-input v-model="_value.technicalTitle"></el-input>
        </el-form-item>
        <el-form-item label="户口地址">
          <el-input v-model="_value.residenceAddress"></el-input>
        </el-form-item>
        <el-form-item label="家庭住址">
          <el-input v-model="_value.homeAddress"></el-input>
        </el-form-item>
        <el-form-item label="人事档案存放单位">
          <el-input v-model="_value.personnelFilePlace"></el-input>
        </el-form-item>
        <el-form-item label="参加工作时间">
          <date v-model="_value.joinWorkTime"></date>
        </el-form-item>
        <el-form-item label="工龄">
          <el-input v-model="_value.workAge"></el-input>
        </el-form-item>
        <el-form-item label="开户行">
          <el-input v-model="_value.bankName"></el-input>
        </el-form-item>
        <el-form-item label="银行账号">
          <el-input v-model="_value.bankAccount"></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="_value.emailAddress"></el-input>
        </el-form-item>

        <el-form-item label="担保人姓名">
          <el-input v-model="_value.guarantorName"></el-input>
        </el-form-item>
        <el-form-item label="与担保人关系">
          <el-input v-model="_value.guarantorRelation"></el-input>
        </el-form-item>
        <el-form-item label="担保人联系电话">
          <el-input v-model="_value.guarantorPhone"></el-input>
        </el-form-item>
        <el-form-item label="担保人工作单位或居住住址">
          <el-input v-model="_value.guarantorAddress"></el-input>
        </el-form-item>
        <el-form-item label="担保人身份证号码">
          <el-input v-model="_value.guarantorIdCard"></el-input>
        </el-form-item>
        <el-form-item label="聘用部门">
          <department v-model="_value.deptId" :checkStrictly="true"></department>
        </el-form-item>
        <el-form-item label="聘用岗位">
          <position v-model="_value.positionIds" :checkStrictly="true"></position>
        </el-form-item>
        <el-form-item label="职级">
          <position v-model="_value.rankLevelIds" type="grade" :checkStrictly="true"></position>
        </el-form-item>
        <el-form-item label="兼职岗位">
          <el-input v-model="_value.ptPosition"></el-input>
        </el-form-item>
        <el-form-item label="拟入职时间">
          <date v-model="_value.preEntryTime"></date>
        </el-form-item>
        <el-form-item label="工作地点">
          <department v-model="_value.workId" :checkStrictly="true"></department>
          <!-- <place v-model="_value.workPlace" type="region"></place> -->
        </el-form-item>
      </el-form>
    </template>
  </block>
</template>

<script>
import Block from "@/components/block";
import Place from "@/components/place.vue";
import Date from "@/components/date.vue";
import Department from "@/components/department.vue";
import Position from "@/components/position.vue";
import RSex from "@/components/r-sex.vue";

export default {
  components: {
    Block,
    Place,
    Date,
    Department,
    Position,
    RSex,
  },
  props: {
    value: {
      type: Object,
    },
  },
  computed: {
    _value: {
      get() {
        this.value.maritalStatus += "";
        this.value.politicalStatus += "";
        this.value.education += "";
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    init() {},
  },
};
</script>

<style lang="scss">
.basic-info {
  .el-form {
    display: flex;
    flex-wrap: wrap;
    .el-form-item {
      width: 50%;
      flex-shrink: 0;
      display: flex;
      .el-form-item__label {
        width: 150px;
      }
    }
  }
}
</style>